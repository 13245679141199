@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {

        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
        }
    }
}


body{
  font-family: 'Satoshi', sans-serif;
  background-color: '#F7F7F8';
  scroll-behavior: smooth !important;
}

.cardPatternImage {
  background-image: url('/public/Images/card-pattern.svg');
  background-repeat: no-repeat;
}

.heroPatternImage {
  background-image: url('/public/Images/hero-pattern.svg');
  background-repeat: repeat-x;
  background-color: '#F7F7F8';
}


.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 3px;
    width: 16px;
    background: #3f3d3d;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }



  @keyframes shimmer {
    0% {
      background-position: -1000px;
    }
    100% {
      background-position: 1000px;
    }
  }
  
  .animate-shimmer {
    animation: shimmer 1.7s infinite linear;
    background: linear-gradient(90deg, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
    background-size: 1000px 100%;
  }