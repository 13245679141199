.ClaimTokensBg {
    background-image: url('/public/Images/blockchain.jpg');
    background-size: cover;
}

.btn-grad {
    background-image: linear-gradient(to right, #003973 0%, #E5E5BE 51%, #003973 100%);
    transition: 0.5s;
    background-size: 300% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    cursor: pointer;
}

.btn-grad:hover {
    background-position: right center;
    /* change the direction of the change here */
    text-decoration: none;
    color: black;
}